.container {
  height: 93%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.innerBox {
  width: 98%;
  height: 100%;
  margin: 10px;
  border: 1px solid black;
  overflow: scroll;
  max-height: 100%;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.innerBox ::-webkit-scrollbar {
  display: none;
}
.addNoteButton {
  width: 40px;
  height: 20px;
  cursor: pointer;
}

.myNotesBox {
  height: 4000px;
  width: 5000px;
  position: relative;
}

.start {
  border-radius: 10px;
  background-color: #041f3e;
  color: white;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.startHolder {
  position: absolute;
  height: 25px;
  left: 30px;
  top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.addLanguageMenu{
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap:10px;
  color: black;
  top:35px;
  padding: 0px 0px 10px 0px;
  border: 1px solid black;
}


.addLanguageMenu div{
  padding: 4px 20px;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid black;
}



.langHolder {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}


.languageButton {
  border: 1px solid black;
  display: flex;
  width: 130px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}


#ball {
  height: 15px; /* Ensure the height and width are equal for a circle */
  width: 15px;
  background-color: black;
  border-radius: 50%; /* 50% border-radius for perfect circle */
  display: flex;
  justify-content: center;
  align-items: center;
}
