
.automationObj{
    border: 1px solid black;
    margin: 2px;
    padding: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 96%;
}
.automationObj input{
    width: 80%;
}
.automationObj textarea{
    width: 80%;
}

.setData{
    padding: 10px 15px;
    border-radius: 8px;
    border:1px solid black;
    background-color: white;
    font: black;
    margin: 20px;
    cursor: pointer;
}

.automationObj svg{
    font-size: 28px;
    cursor: pointer;
    margin-left:auto;
}
