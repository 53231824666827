.container {
  display: flex;
  flex-direction: column;
  margin: 0px 10px 10px 10px;
}
.save {
  width: 40px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ContextOptions {
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  gap: 30px;
}

.ContextOptions div {
  padding: 6px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.pushButton{
  font-size: 12px;
  padding: 10px;
  border-radius: 8px;
  border:1px solid black;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;
}