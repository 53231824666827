.selectHolder{
    display: flex;
    gap:5px;
    align-items: center;
}

.container select{
    max-width: 100px;
}


.container{
    background-color: white;
    display: flex;
    gap: 20px;
    padding: 5px;
    flex-direction: column;
    margin-left: 30px;
}

.container a{
    color: black;
}

.pathname{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 70%;
}