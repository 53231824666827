.headline {
  display: flex;
  gap: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.oneSection {
  border: 1px solid black;
  padding: 5px;
}

.options {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
}

.options div {
  cursor: pointer;
}

.fetch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.json {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 10px;
}
