.container {
  width: 94%;
  margin-top: 20px;
  border: 1px solid black;
  border-top-width: 0;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  min-height: 800px;
}

.container button {
  border: 1px solid #041f3e;
}

.notesBox {
  width: 100%;
  border: 1px solid black;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.headlineInputButton{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #041f3e;
  height: 40px;
  max-width: 90px;
  border-radius: 8px;
}
.headlineInputButton input{
  width: 80%;
  outline: none;
  background-color: #041f3e;
  border: none;
  color: white;
}

.headLineHolder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.headLineContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  overflow: scroll;
}



.noteContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding-left: 2px;
}

.FinalHolder svg {
  font-size: 28px;
  cursor: pointer;
}

.dataButton {
  height: 40px;
  min-width: 70px;
  background-color: white;
  color: #041f3e;
  border-radius: 8px;
  cursor: pointer;
}

.notesTitle {
  margin: 5px;
  width: 75%;
}

.FinalHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.prodBox {
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.icon {
  margin-top: 6px;
  border: 50%;
  font-size: 35px;
  cursor: pointer;
}

.container2 {
  width: 100%;
  display: flex;
}
