.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settingsTab {
  width: 80%;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}


.priorityLanguages{
  display: flex;
  gap:5px;
  overflow-x: scroll;
  min-height: 50px;
}
.priorityLanguages input{
  height: 25px;
  max-height: 25px;
}

.priorityLanguages svg{
  cursor: pointer;
  font-size: 20px;
}

.priorityBox{
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  gap:5px;
  margin-right: 2px;
}

.priObj{
  display: flex;
  align-items: center;
  gap:3px;
  border:0.1px solid black;
  padding: 5px;
  
}



.delete{
  width: 150px;
  background-color: red;
  color:white;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 3px;
}

.inputBox{
  display: flex;
  gap:5px;
}
.inputBox2{
  display: flex;
  gap:5px;
}

.inputBox input {
  width: 300px;
}

.gitButton {
  width: 20%;
}

.settingsC {
  width: 100%;
}

.demoStyling {
  display: flex;
  gap: 5px;
}

.styling {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: 1px solid black;
  padding: 5px;
  width: 400px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.column input {
  width: 200px;
  height: 22px;
}

.column div {
  height: 22px;
}
