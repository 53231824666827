.container input {
  width: 50%;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid black;
  align-items: flex-end;
}
.container button {
  width: 40px;
}

.container select {
  max-width: 250px;
}

.addButton {
  margin-top: 20px;
}
