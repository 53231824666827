.container{
    padding:10px 0 200px 50px;
    display: flex;
    flex-direction: column;
    gap:7px;
    
}

.container textarea{
    resize: none;
}


.box{
    display: flex;
    overflow-x: scroll;
    gap:20px
    
}

.box textarea{
    min-width: 400px;
    min-height: 600px;
}