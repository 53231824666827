.container{
    width: 100%;
    display: flex;
    justify-content: center;
}



.fullContainer{
    margin-top: 100px;
    width: 80%;
    min-height: 400px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap:10px;
}

.innerContainer{
    width: 100%;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap:22px;
    padding-bottom: 30px;
    overflow-x: scroll;
}

.start{
    margin-top: 100px;
    width: 80%;
    border: 1px solid black;
    min-height: 400px;
    padding: 10px;
    margin-bottom: 100px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap:7px;
}

.start input{
    width: 70%;
}

.titles{
    display: flex;
    gap:5px;
    align-items: center;
    flex-wrap: wrap;

}
.titles input{
    outline: none;
    width: 200px;
}

.exampleTitles{
    display: flex;
    flex-wrap: wrap;
}


.titleHolder{
    display: flex;
    align-items: center;
}



.exampleTitle{
    padding: 3px;
    border: 1px solid black;
    margin:3px;
    cursor: pointer;
    min-height: 20px;
}

.innerContainer svg{
    font-size: 24px;
    cursor: pointer;
}



.selectHolder{
    display: flex;
    gap:5px;
    align-items: center;
}

.selectHolder select{
    max-width: 100px;
}


.goButton{
    border-radius: 8px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid black;
    width: 30px;
}


