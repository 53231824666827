.container {
  width: 400px;
  border: 1px solid black;
  position: absolute;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 200px;
  border-radius: 10px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.header svg {
  cursor: pointer;
  font-size: 30px;
}

.container input {
  border-radius: 1px;
  height: 40px;
  width: 60%;
  margin-bottom: 10px;
  border-width: 1px;
  border-radius: 5px;
}

.container button {
  padding: 8px;
  border: 2px;
  border-color: black;
  border-style: solid;
  background-color: #041f3e;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 5px;
}
