.header {
  background-color: #041f3e;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
}
.headerLogo {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  color: white;
  font-size: 20px;
  margin-left: auto;
  margin-right: 10px;
}

.HeaderButtons {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.HeaderButtons a {
  cursor: pointer;
}

.headerButton {
  margin-left: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  text-decoration: none;
}


.CompanyContainer {
  display: flex;
  direction: row;
}

.companySearch {
  margin: 5px;
  width: 200px;
}

.count {
  width: 20px;
  height: 20px;
  background-color: red;
  margin-left: 3px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
