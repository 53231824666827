.container {
  position: relative;
  display: flex;
}
.inputField {
    border: 1px solid #ccc;
    padding: 4px 2px;
    font-size: 16px;
    cursor: text;
    display: inline-block;
    white-space: pre-wrap; /* Preserve whitespace */
}

.suggestion {
    display: flex;
    align-items: center;
    color: grey;
    font-size: 16px;
    padding: 8px;
    font-weight: 400;
    height: 10px;
}
 