.oneUrlBox {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid black;
}
.fetchOrScrape {
  display: flex;
  align-items: center;
  gap: 10px;
}

.urlContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rowGap {
  display: flex;
  gap: 4px;
}
