.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  border-right: 1px solid grey;
  overflow: hidden;
  z-index: 3;
  background-color: #041f3e;
  overflow-y: scroll;
}


.redCount{
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
}


.companySearch {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.inputContainer {
  padding: 0 !important;
  border-top: 0.1px solid white;
  display: flex;
  background-color: #041f3e;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: white;
  top:50px;
  height: 40px;
  width: 100%;
}

.container svg {
  font-size: 40px;
}

.company {
  cursor: pointer;
  border-top: 0.1px solid white;
  display: flex;
  background-color: #041f3e;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: white;
  min-height: 50px;
  width: 100%;
  height: 100px;
}

.buttonContainer{
  width: 100%;
  padding-bottom: 50px;
  padding-top: 10px;
  display: flex;
  align-items: start;
  justify-content: center;
  border-top: 0.1px solid white;
}

.buttonContainer svg {
  background-color: #041f3e;
  padding: 10px;
  color: white;
  cursor: pointer;
  font-size: 35px;
}


.mainSettings{
  cursor: pointer;
  width: 100%;
  display: flex;
  background-color: #041f3e;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: white;
  min-height: 45px;
}