
.container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    display: flex;
    width: 90%;
    margin-top: 40px;
    border:1px solid black;
    min-height: 800px;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}

.content svg{
    font-size: 31px;
    cursor: pointer;
}

.automationObj{
    border: 1px solid black;
    margin: 2px;
    padding: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 96%;
}
.automationObj input{
    width: 80%;
}
.automationObj textarea{
    width: 80%;
}

.automationObj svg{
    font-size: 28px;
    cursor: pointer;
    margin-left:auto;
}


.notePreview{
    display: flex;
    padding: 10px;
    flex-direction: column;
    gap:10px
}
.notePreview input{
    width:80%;
}



.preview{
    position: fixed;
    width: 80%;
    height: 90%;
    border: 1px solid black;
    top:60px;
    background-color: white;
    z-index: 1000;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
}
.preview svg{
    margin:10px 10px 0 auto;
    font-size: 30px;
    cursor: pointer;
}
