.container {
    display: flex;
    justify-content: center;
    width: 99%;
    align-items: center;
    width: 95%;
    flex-direction: column;
    border: 1px solid black;
    padding: 5px 0;
    position: relative;
    background-color: white;
}
.container input{
    outline: none;
}

.containerHeadline{
    display: flex;
    justify-content: center;
    width: 99%;
    align-items: center;
    width: 95%;
    flex-direction: column;
    border: 1px solid white;
    padding: 5px 0;
    position: relative;
    background-color: #041f3e;
    color: white;

}
.containerHeadline svg{
    color: white;
    font-size: 20px;
    cursor: pointer;
}


.container svg{
    font-size: 20px;
    cursor: pointer;
    color: #041f3e
}



.notesTitle {
    margin: 5px;
    width: 75%;
}


.noteHeader{
    display: flex;
    margin-left: auto;
    margin-right: 10px;
    gap:10px;
    margin-top: 15px;
}

.buttons{
    margin-left: auto;
    display: flex;
    gap:5px;
    margin-bottom: 15px;
    margin-right: 10px;
}


.noteList{
    width: 100%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
}

