.button {
  height: 30px;
  width: 70px;
  position: absolute;
  top: 20px;
  right: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 20px;
}


.redCount{
  border-radius: 50%;
  background-color: red;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  position: absolute;
  left: 30px;
  
}