.container {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  width: 95%;
  height: 95%;
  border: 1px solid black;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 10px;
  flex-direction: column;
}

.innerContainer button {
  width: max-content;
  cursor: pointer;
}

.oneUrlBox {
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid black;
}

.fetchOrScrape {
  display: flex;
  align-items: center;
  gap: 10px;
}

.urlInput {
  width: 300px;
}

.changePartBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.changePartBox svg {
  border: 1px solid black;
  padding: 5px;
  border-radius: 50%;
}
