.container {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  gap:5px;
  align-items: center;
  width: 100%;
  justify-content: center;

}

.addNoteButton{
  cursor: pointer;
  font-size: 30px;
}

.container button {
  border: 1px solid #041f3e;
}





.container2 {
  width: 100%;
  display: flex;
}

