.container input {
  width: 50%;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
  border: 1px solid black;
  padding: 10px;
}
.container button {
  width: 40px;
}
.container select {
  max-width: 250px;
}

.one {
  display: flex;
  gap: 10px;
}
