.myNote {
  width: 270px;
  border: 1px solid black;
  background-color: #041f3e;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 4px;

}

.myNoteHeader {
  width: 100%;
  background-color: #041f3e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
}

.textAreaHolder {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ballAndInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  gap: 5px;
}

#ball {
  height: 15px; /* Ensure the height and width are equal for a circle */
  width: 15px;
  background-color: black;
  border-radius: 50%; /* 50% border-radius for perfect circle */
  display: flex;
  justify-content: center;
  align-items: center;
}

#titleBall {
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.ballContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* Adjust as needed */
  width: 20px; /* Adjust as needed */
}
